.content-all-continer {
    display: flex;
    justify-content: center;
    width: 100%;
    
}

.wrapper-content {
    width: 100%;
    max-width: 1200px;
}