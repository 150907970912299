.banner-container {
    width: 100%;
    height: fit-content;
    overflow: hidden;
    // @media screen and (max-width:1000px) {
    //     display: none;
    // }

    img {
        width: 100%;
    }

    .btn-dynamic {
        position: absolute;
        color: white;

        border-radius: 50px;
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        &:hover {
            background-color: #9BBEC8;
        }
    }

    .btn-view-left {

        left: 0;
        top: 50%;
    }

    .btn-view-right {
        right: 0;
        top: 50%;
    }

}