.air-conditioner-repairing-container {

    .slogan-container {
        display: flex;
        text-align: center;
        padding: 40px 0 0 0;
        flex-direction: column;
        gap: 15px;

        .slogan-row-1 {
            font-size: 25px;
            font-weight: 700;
        }

        .slogan-row-2 {
            font-size: 21px;
            font-weight: 700;
        }

        .slogan-row-3 {
            font-size: 18px;
            font-weight: 700;
            color: red;
        }
    }

    .air-conditioner-content-03 {
        tr {
            border: 1px solid black;

            td {
                border: 1px solid black;
            }
        }
    }
}

.text {
    font-size: 15px;
    color: black;

    @media screen and (max-width: 1000px) {
        font-size: 35px;
        color: black;
    }
}