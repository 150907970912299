.your-table-class {
    border-collapse: collapse;
    width: 100%;
  }
  
  .your-table-class th, .your-table-class td {
    border: 1px solid #dddddd;
    text-align: center;
    padding: 8px;
  }
  
  .your-table-class th {
    background-color: #f2f2f2;
  }
  .cleaning-the-washing-machine-container {

    .slogan-container {
        display: flex;
        text-align: center;
        padding: 40px 0 0 0;
        flex-direction: column;
        gap: 15px;

        .slogan-row-1 {
            font-size: 25px;
            font-weight: 700;
        }

        .slogan-row-2 {
            font-size: 21px;
            font-weight: 700;
        }

        .slogan-row-3 {
            font-size: 18px;
            font-weight: 700;
            color: red;
        }
    }
}

.text {
    font-size: 15px;
    color: black;

    @media screen and (max-width: 1000px) {
        font-size: 35px;
        color: black;
    }
}