.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

  @media screen and (max-width:1000px) {
    padding: 0 80px;
    gap: 40px;
  }

  .card {
    border: 1px solid #ddd;
    padding: 15px;
    text-align: center;
    margin-bottom: 20px;
    cursor: pointer;
    
    .card-image {
      height: 400px;
    }

    &:hover {
      box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    }

    @media screen and (max-width:1000px) {
      box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
      h3 {
        font-size: 40px;
      }
      .card-image {
        height: auto;
      }
    }
  }
}