@import "../../css/colo.scss";

.footer-container {
    display: flex;
    flex-direction: column;
    color: white;
    align-items: center;
    height: auto;
    background-color: $background-colo-footer-contact;

    .footer-infor {
        display: flex;
        flex-wrap: wrap;
        height: auto;
        background-color: $background-colo-footer-contact;
        max-width: 1250px;
        color: #5800FF;
        color: black;
        font-size: 17px;
        padding: 20px 20px;

        @media screen and (max-width: 1000px) {
            gap: 80px;
            font-size: 40px;
            margin: 0 25px 0 25px;
        }


        .title {
            color: black;
            font-weight: bold;
            font-size: 20px;

            @media screen and (max-width: 1000px) {
                font-size: 40px;
            }
        }

        .contact-infor-container {
            display: flex;
            flex-direction: column;
            gap: 20px;

            .contact-row {
                display: flex;
                gap: 8px;
                align-items: start;
            }

            .left-col {}

            .right-col {}
        }

        .footer-row {
            display: flex;
            gap: 20px;
            align-items: center;
            border-bottom: 1px solid #164863;
            margin: 0 20px 0 0;
            padding: 5px;

            @media screen and (max-width: 1000px) {
                margin: 0 20px 0 20px;
            }

            svg {
                color: gray;
                font-size: 15px;

                @media screen and (max-width: 1000px) {
                    gap: 60px;
                    font-size: 30px;
                }
            }

            a {
                color: #164863;
                text-decoration: none;

                &:hover {
                    color: rgb(171, 77, 0);
                }
            }
        }

        .about-us-container {
            display: flex;
            flex-direction: column;
            gap: 10px;

        }

        .footer-reapaire-container {
            display: flex;
            flex-direction: column;
            gap: 10px;
        }

        .footer-maintance-container {
            display: flex;
            flex-direction: column;
            gap: 10px;
        }

        .icon-contact {
            width: 100%;

            ul {
                padding: 0;
                display: flex;
                justify-content: center;
                width: 100%;
                text-align: center;


                li {
                    list-style: none;
                    display: flex;
                    justify-content: start;

                    @media screen and (max-width:1000px) {
                        justify-content: center;
                    }

                    a {
                        padding: 10px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 40px;
                        height: 40px;
                        background-color: #fff;
                        font-size: 1rem;
                        border-radius: 50%;
                        position: relative;
                        overflow: hidden;
                        border: 3px solid #fff;
                        z-index: 1;

                        @media screen and (max-width:1000px) {
                            width: 100px;
                            height: 100px;
                            font-size: 50px;
                        }

                        .icon {
                            position: relative;
                            color: #262626;
                            transition: .5s;
                            z-index: 3;
                        }

                        &:hover {
                            .icon {
                                color: #fff;
                                transform: rotateY(360deg);
                            }

                            &:before {
                                top: 0;
                            }
                        }

                        &:before {
                            content: "";
                            position: absolute;
                            top: 100%;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            background: #f00;
                            transition: .5s;
                            z-index: 2;
                        }
                    }

                    &:nth-child(1) {
                        a {
                            &:before {
                                background: #3b5999;
                            }
                        }
                    }

                    &:nth-child(2) {
                        a {
                            &:before {
                                background: #55acee;
                            }
                        }
                    }

                    &:nth-child(3) {
                        a {
                            &:before {
                                background: #0077b5;
                            }
                        }
                    }

                    &:nth-child(4) {
                        a {
                            &:before {
                                background: #dd4b39;
                            }
                        }
                    }
                }
            }

        }
    }

    .design-infor-container {
        width: 100%;
        height: 50px;
        background-color: $background-colo-footer-disign-infor;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}