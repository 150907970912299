* {
  margin: 0;
  padding: 0;
}

.divider-vertical-with {
  background-color: #a575ff;
  width: .1px;
  height: 35%;
}

.title {
  font-size: 30px;
  font-weight: 500;

  @media screen and (max-width:1000px) {
    font-size: 50px;
  }
}

.content {
  font-size: 16px;
  @media screen and (max-width:1000px) {
    font-size: 40px;
    padding: 0 50px 0 50px;
  }
}

.list-content {
  font-size: 16px;

  @media screen and (max-width:1000px) {
    font-size: 40px;
    padding: 0 50px 0 50px;
  }
}

.table {
  @media screen and (max-width:1000px) {
    font-size: 35px;
  }
}