@import "../../css/colo.scss";

.nav-container {
    background-color: $background-colo-nav;
    display: flex;
    color: white;
    justify-content: center;
    height: auto;
    font-size: 14px;
    position: sticky;
    top: 0px;
    z-index: 999;

    .main-content-lg {
        display: flex;
        height: auto;
        padding: 5px;
        max-width: 1250px;
        align-items: center;

        @media screen and (max-width: 1000px) {
            display: none;
        }

        .logo-container {
            display: flex;
            justify-content: center;
            .logo {
                height: 80px;
            }
        }

        .nav-content {
            display: flex;
            align-items: center;

            ul {
                display: flex;
                flex-wrap: wrap;
                list-style: none;
                font-size: 15px;
                margin: 0;

                li {
                    padding: 5px 10px 5px 10px;

                    a {
                        font-weight: bold;
                        color: white;
                        text-decoration: none;

                        &:hover {
                            color: yellow !important;
                        }
                    }

                    svg {
                        margin-left: 4px;
                        font-size: 13px;
                    }

                }

                .active {
                    border: 1px solid yellow;
                    border-radius: 20px;
                    color: yellow;

                    a {
                        color: yellow;
                    }
                }

            }
        }
    }

    .main-content-md {
        display: flex;
        height: auto;
        padding: 10px 30px 10px 30px;
        max-width: 1250px;
        align-items: center;
        justify-content: space-between;

        @media screen and (min-width: 1000px) {
            display: none;
        }
        
        .logo-container {
            display: flex;
            justify-content: start;
            font-size: 65px;
            .logo {
                height: 100px;
            }
        }

        .nav-content {
            display: flex;
            align-items: center;
            justify-content: end;

            .icon-open-drawer {
                font-size: 65px;
            }
        }
    }
}

a {
    text-decoration: none;
    color: black;
}