/* Maintenance.scss */
.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;

  @media screen and (max-width:1000px) {
    justify-content: center;
  }
}

.image {
  max-width: 100%;
  height: 360px;
  margin-bottom: 10px;
  
  @media screen and (max-width:1000px) {
    height: auto;
  }
}

h3 {
  font-size: 1.2em;
  /* Giảm kích thước của tiêu đề */
}

p {
  font-size: 0.9em;
  /* Giảm kích thước của nội dung */
}